import React from "react";

import { LayoutGeneral } from "src/layout/LayoutGeneral";

const CertificadoGreenGuard = (props) => (
  <LayoutGeneral {...props}>
    Los productos que consiguen la certificación GreenGuard han demostrado
    científicamente que pasan los estándares más rigurosos en cuanto a emisiones
    químicas, ayudando a reducir la polución en interiores y minimizando el
    riesgo de exposición química. ¿Qué incluye el GreenGuard? El estándar de
    certificación GREENGUARD Gold incluye criterios sanitarios para los
    productos químicos y requiere niveles de emisiones de COV totales bajos,
    para ayudar a garantizar que los productos sean aceptables para su uso en
    entornos del tipo de escuelas e instalaciones de atención médica. ¿Por qué
    GreenGuard? El programa de certificación GREENGUARD de UL es reconocido y
    referenciado en numerosos programas, estándares y especificaciones de
    construcción en todo el mundo. Los productos con la Certificación GREENGUARD
    o la Certificación GREENGUARD Gold pueden contribuir al logro de puntos en
    los sistemas establecidos de calificación de edificios ecológicos, como
    LEED, BREEAM, Fitwel y otros, y satisfacen los criterios del código u
    ordenanza y cumplen con los requisitos específicos de calidad del aire
    interior (RFP).
  </LayoutGeneral>
);

export default CertificadoGreenGuard;
